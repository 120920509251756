import React, { useState } from "react"
import styled from "styled-components"
import { NavigationBar } from "./NavigationBar"
import Dropdown from "./Dropdown"
import SocialFooter from "../main/SocialFooter"
import DisclosureFooter from "../main/DisclosureFooter"

export default function NotFound() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <NavigationBar toggle={toggle} />
      <PageContainer>
        <div>
          <h3>The page you're looking for no longer exists.</h3>
          <p>
            If you have questions, please email{" "}
            <a href="mailto:support@mrpaulxavier.com">
              support@mrpaulxavier.com
            </a>
            .
          </p>
        </div>
      </PageContainer>
      <HorizontalLine />
      <SocialFooter />
      <section className="themes__panel-black">
        <DisclosureFooter />
      </section>
    </>
  )
}

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, auto);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  color: black;
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  padding-top: 150px;
  height: 80vh;
`
const HorizontalLine = styled.div`
  width: 100vw;
  border-top: 1px solid black;
`
